import { Box, FlexBox, Markdown, Text } from '@codecademy/gamut';
import { SparkleIcon } from '@codecademy/gamut-icons';
import styled from '@emotion/styled';
import { useWindowSize } from 'react-use';

import { Message } from '../message';
import { InlinePrompts } from './InlinePrompt';
import {
  getInnerPrompts,
  isPromptList,
  MarkdownUlProps,
} from './prompt-helpers';

const StyledMarkdown = styled(Markdown)`
  font-weight: 400;
  font-size: 16px;
`;

const CircleBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  background-color: ${({ theme }) => theme.colors['navy-100']};
  border-radius: 50%;
  flex-shrink: 0;
  position: relative;
  align-self: end;
  margin-right: 10px;
`;

export const MessageBubble = ({
  message: { content, role },
}: {
  message: Message;
}) => {
  const { width } = useWindowSize();
  return (
    <FlexBox justifyContent={{ user: 'right', assistant: 'left' }[role]}>
      {width >= 768 && role === 'assistant' && (
        <CircleBox>
          <SparkleIcon />
        </CircleBox>
      )}
      <Box
        bg={
          { user: 'blue-500', assistant: 'navy-100' }[role] as
            | 'blue-500'
            | 'navy-100'
        }
        p={{ _: 12, xs: 16 }}
        borderRadius="lg"
        {...{
          ['borderRadiusBottom' +
          { user: 'Right', assistant: 'Left' }[role]]: 0,
          [{ user: 'ml', assistant: 'mr' }[role]]: 32,
        }}
      >
        {role === 'assistant' ? (
          <StyledMarkdown
            text={content}
            overrides={{
              ul: {
                processNode: (node, props: MarkdownUlProps) =>
                  isPromptList(node) ? (
                    <InlinePrompts
                      key={props.key}
                      prompts={getInnerPrompts(props)}
                    />
                  ) : (
                    <ul {...props} />
                  ),
              },
            }}
          />
        ) : (
          <Text color="white">{content}</Text>
        )}
      </Box>
    </FlexBox>
  );
};
