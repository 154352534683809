import { Anchor } from '@codecademy/gamut';
import { css } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import { HubCard } from '@mono/brand';
import { useTracking } from '@mono/data/use-tracking';
import { UserClickData } from '@mono/tracking';
import { NextLink } from '@mono/ui/components';
import { getCatalogPath } from '@mono/util/url';
import React from 'react';

import {
  CatalogCategoryType,
  CategoryData,
} from '~/components/CatalogPage/types';

const HubCardAnchorWrapper = styled(Anchor)(
  css({ alignItems: 'stretch', display: 'grid', width: '100%' })
);
export type LinkedHubCardProps = {
  item: CategoryData;
  trackingData: Omit<UserClickData, 'slug' | 'target'>;
};

export const LinkedHubCard: React.FC<LinkedHubCardProps> = ({
  item,
  trackingData,
}) => {
  const { trackUserClick } = useTracking();
  const href = getCatalogPath({
    categoryType: item?.categoryType || '',
    category: item.slug,
  });

  return (
    <NextLink href={href} passHref>
      <HubCardAnchorWrapper
        variant="interface"
        onClick={() => {
          trackUserClick({
            ...trackingData,
            slug: item.slug,
            target: 'hub_card',
            href,
          });
        }}
      >
        <HubCard
          backgroundPosition={
            item?.categoryType === CatalogCategoryType.Language
              ? 'left'
              : 'right'
          }
          backgroundImage={`https://static-assets.codecademy.com/assets/components/cards/explore-category-card/${item.categoryType}/${item.slug}.svg`}
          eyebrowTitle="Explore all"
          title={item?.title || ''}
        />
      </HubCardAnchorWrapper>
    </NextLink>
  );
};
