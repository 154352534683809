import {
  Alert,
  Anchor,
  Box,
  Dialog,
  FlexBox,
  IconButton,
  InfoTip,
  Text,
} from '@codecademy/gamut';
import {
  ArrowChevronDownIcon,
  SparkleIcon,
  TrashIcon,
} from '@codecademy/gamut-icons';
import { theme } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import { sendCaptchaGuardedRequest } from '@mono/ui/captcha';
import { NextLink } from '@mono/ui/components';
import { useEffect, useMemo, useRef, useState } from 'react';

import { trackUserClick } from '~/libs/tracking';

import { AnimatedLoadingDots } from '../../../InterviewSimulator/components/AnimatedLoadingDots';
import { createUserMessage, Message } from '../message';
import {
  setAnonAIOpen,
  setInjectedMessage,
  useInjectedMessage,
} from '../state';
import { ChatInput } from './ChatInput';
import { Greeting } from './Greeting';
import { anonChat } from './helpers/api';
import { b36uuid } from './helpers/b36uuid';
import { MessageBubble } from './MessageBubble';

const ScrollBox = styled(FlexBox)`
  overscroll-behavior: contain;
`;

const StyledText = styled(Text)`
  color: rgba(16, 22, 47, 0.75);
`;

type ChatBoxProps = {
  pageName: string;
};

const getChatToken = async (recaptchaToken: string, version: number) => {
  try {
    const f = await fetch('/api/portal/anon-chat-token', {
      method: 'POST',
      body: JSON.stringify({ recaptchaToken, version }),
    });
    const chatToken = await f.json();
    return { allowed: true, result: chatToken };
  } catch {
    return { allowed: false, result: 'Error while verifying recaptcha token' };
  }
};

type ChatSessionState = {
  messages: Message[];
  conversationId: string;
  chatToken: string;
  expId: string;
  disclaimerSeen: boolean;
};
const CHAT_SESSION_STATE_KEY = 'cc_anon_ai_chat_session_state';

type AlertProps = {
  msg: React.ReactNode;
  type: 'error' | 'notice' | 'general';
};

const getExpId = () =>
  document.cookie
    .split('; ')
    .find((c) => c.startsWith('_cc_exp_id'))
    ?.split('=')[1] as string;

function saveSessionState(state: ChatSessionState) {
  sessionStorage.setItem(CHAT_SESSION_STATE_KEY, JSON.stringify(state));
}

const AlertAnchor = styled(Anchor)`
  color: var(--color-white);
`;

function getSessionState() {
  if (typeof window === 'undefined') {
    return null;
  }
  const storedStateJson = sessionStorage.getItem(CHAT_SESSION_STATE_KEY);
  if (!storedStateJson?.length) {
    return null;
  }
  const storedState = JSON.parse(storedStateJson) as ChatSessionState;

  if (storedState?.expId === getExpId()) {
    return storedState;
  }
  return null;
}

export const ChatBox: React.FC<ChatBoxProps> = ({ pageName }) => {
  const initSessionState = useMemo(getSessionState, []);
  const injectedMessage = useInjectedMessage();
  const [chatToken, setChatToken] = useState<string>(
    initSessionState?.chatToken ?? ''
  );
  const [conversationId, setConversationId] = useState<string>(
    initSessionState?.conversationId ?? b36uuid()
  );

  const [messages, setMessages] = useState<Message[]>(() => {
    const _messages = initSessionState?.messages ?? [];
    if (
      injectedMessage &&
      !_messages.some((m) => m.id === injectedMessage.id) &&
      _messages[_messages.length - 1]?.content !== injectedMessage.content
    ) {
      _messages.push(injectedMessage);
    }
    return _messages;
  });

  const [disclaimerSeen, setDisclaimerSeen] = useState(
    !!initSessionState?.disclaimerSeen
  );

  const [captcha, setCaptcha] = useState<JSX.Element | null>(null);
  const [alert, setAlert] = useState<AlertProps | null>(
    disclaimerSeen
      ? null
      : {
          msg: (
            <Text variant="p-small">
              {`By using this chat, you agree to the Codecademy `}
              <AlertAnchor href="https://www.codecademy.com/policy">
                Privacy Policy
              </AlertAnchor>
              {` and `}
              <AlertAnchor href="https://www.codecademy.com/terms">
                Terms of Use
              </AlertAnchor>
              . Do not enter any personally identifying or confidential
              information.
            </Text>
          ),
          type: 'general',
        }
  );
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  // If the last message is a user message, we are awaiting a response
  const awaitingResponse = useMemo(
    () => messages[messages.length - 1]?.role === 'user',
    [messages]
  );

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const scrollBoxRef = useRef<HTMLDivElement>(null);
  const chatBoxContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!chatToken) {
      // use captcha to get a chat token
      const [_captcha, promise] = sendCaptchaGuardedRequest({
        action: 'create_chat_token',
        requestV2: async (recaptchaToken) => getChatToken(recaptchaToken, 2),
        requestV3: async (recaptchaToken) => getChatToken(recaptchaToken, 3),
        reCaptchaKey: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
      });

      // "show" the captcha element (it may be invisible)
      setCaptcha(_captcha);

      promise.then((r) => {
        if (r.status === 'complete') {
          // if we receive a chat token, set it
          setChatToken(r.result);
        } else {
          setAlert({
            msg: 'Sorry, there was an error processing your request.',
            type: 'error',
          });
        }
      });
    }
  }, [chatToken]);

  const tokenRetryCount = useRef(0);

  useEffect(() => {
    /*
     * If we are awaiting a response (the last message is a user message)
     * and if we have loaded a chatToken
     * make a request to get an AI response
     */
    if (awaitingResponse && chatToken && conversationId) {
      anonChat({
        body: JSON.stringify({
          messages,
          chatToken,
          conversationId,
        }),
      }).then(([result, err]) => {
        if (err === null) {
          setMessages([...messages, result as Message]);
        } else if (
          err.message === 'Invalid chat token' &&
          tokenRetryCount.current < 3
        ) {
          // eslint-disable-next-line no-plusplus
          tokenRetryCount.current++;
          setChatToken('');
        } else if (err.message === 'Rate limit error') {
          setAlert({
            msg: 'You have reached the maxiumum number of messages within the hour. Please check back after an hour to chat more.',
            type: 'notice',
          });
          if (messages[messages.length - 1].role === 'user') {
            setMessages(messages.slice(0, -1));
          }
        } else {
          setAlert({
            msg: 'Sorry, there was an error processing your request.',
            type: 'error',
          });
          if (messages[messages.length - 1].role === 'user') {
            setMessages(messages.slice(0, -1));
          }
        }
      });
    }
  }, [messages, awaitingResponse, chatToken, conversationId]);

  useEffect(() => {
    // Only necessary for when a user clicks a prompt button after the chatbox is open
    if (
      injectedMessage &&
      !messages.some((m) => m.id === injectedMessage.id) &&
      messages[messages.length - 1]?.content !== injectedMessage.content
    ) {
      setMessages([...messages, injectedMessage]);
    }
    if (injectedMessage) {
      setInjectedMessage(null);
    }
  }, [messages, injectedMessage]);

  useEffect(() => {
    // Save updates to session storage
    if (chatToken) {
      saveSessionState({
        messages,
        conversationId,
        chatToken,
        expId: getExpId(),
        disclaimerSeen,
      });
    }
  }, [messages, conversationId, chatToken, disclaimerSeen]);

  const deleteChat = () => {
    // clear chat and set a new conversationId
    setMessages([]);
    const _conversationId = b36uuid();
    setConversationId(_conversationId);
    saveSessionState({
      messages: [],
      conversationId: _conversationId,
      chatToken,
      expId: getExpId(),
      disclaimerSeen,
    });
    setAlert({
      msg: 'Messages cleared',
      type: 'general',
    });
  };

  useEffect(() => {
    if (scrollBoxRef.current) {
      scrollBoxRef.current.scrollTop = scrollBoxRef.current.scrollHeight;
    }
    const hourAgo = new Date(+new Date() - 1000 * 60 * 60).toISOString();
    const lastHourMessageCount = messages.filter(
      (m: Message) => m.role === 'assistant' && m.timestamp >= hourAgo
    ).length;
    if (lastHourMessageCount >= 50) {
      setAlert({
        msg: 'Please check back after an hour to chat more.',
        type: 'notice',
      });
    } else if (lastHourMessageCount >= 45) {
      setAlert({
        msg: 'Just a quick note, we may soon reach our chat limit. Apologies for the inconvenience if this conversation is cut short.',
        type: 'notice',
      });
    }
    if (!messages.length) {
      chatBoxContainerRef.current?.focus();
    }
  }, [messages]);

  // use ref so that we can avoid declaring dependencies and only run with initial value
  const trackingInfo = useRef({
    pageName,
    conversationId,
    injectedMessage,
  });
  useEffect(() => {
    const prompt = trackingInfo.current.injectedMessage?.content;
    trackUserClick({
      context: 'anon_ai_assistant',
      target: 'anon_ai_assistant_opened',
      misc: JSON.stringify({
        conversationId: trackingInfo.current.conversationId,
        ...(prompt ? { prompt } : {}),
      }),
      page_name: trackingInfo.current.pageName,
    });
    chatBoxContainerRef.current?.focus();
  }, []);

  // used to arrange messages for screen reader
  const lastMessage = messages[messages.length - 1];
  const messageToRead = lastMessage?.role === 'assistant' ? lastMessage : null;
  const messagesNotToRead = messageToRead ? messages.slice(0, -1) : messages;

  return (
    <FlexBox
      flexDirection="column"
      bottom={64}
      ref={chatBoxContainerRef}
      tabIndex={0}
      boxShadow="6px 6px var(--color-navy)"
      background={theme.colors.paleBlue}
      borderRadius="md"
      border={1}
      width={{ _: 'calc(100vw - 24px)', xs: 416, sm: 502 }}
      maxHeight="100%"
      height={672}
      onKeyDown={(e) => {
        if (e.key === 'Escape' && !showDeleteDialog) {
          trackUserClick({
            context: 'anon_ai_assistant',
            target: 'anon_ai_assistant_closed',
            misc: JSON.stringify({ conversationId }),
            page_name: pageName,
          });
          setAnonAIOpen(false);
        }
      }}
      aria-label="AI Learning Assistant"
    >
      <FlexBox
        justifyContent="space-between"
        background={theme.colors['blue-500']}
        p={{ _: 12, xs: 16 }}
        borderBottom={1}
        borderColor="border-tertiary"
        borderRadiusTop={'3px' as 'md'}
        zIndex={1}
      >
        <FlexBox alignItems="center">
          <SparkleIcon size={24} color="white" />
          <Text color="white" fontSize={18} pt={4} pl={8} as="h2">
            AI Learning Assistant
          </Text>
        </FlexBox>

        <FlexBox>
          {messages.length > 0 && (
            <IconButton
              mode="dark"
              aria-label="clear-chat"
              variant="secondary"
              icon={TrashIcon}
              onClick={() => setShowDeleteDialog(true)}
              size="small"
              tip="Clear messages"
              mr={4}
            />
          )}
          <IconButton
            mode="dark"
            aria-label="minimize-icon"
            variant="secondary"
            icon={ArrowChevronDownIcon}
            onClick={() => {
              trackUserClick({
                context: 'anon_ai_assistant',
                target: 'anon_ai_assistant_closed',
                misc: JSON.stringify({ conversationId }),
                page_name: pageName,
              });
              setAnonAIOpen(false);
            }}
            tip="Minimize chat"
            size="small"
          />
        </FlexBox>
      </FlexBox>
      <ScrollBox
        ref={scrollBoxRef}
        flexDirection="column"
        flexGrow={1}
        overflowX="hidden"
        overflowY="auto"
        p={{ _: 12, xs: 16 }}
        gap={8}
      >
        {messages.length === 0 && (
          <Greeting
            onPromptSelect={() => {
              setAlert(null);
              setDisclaimerSeen(true);
              inputRef.current?.focus();
            }}
          />
        )}
        {messagesNotToRead.map((m) => (
          <Box key={m.id}>
            <MessageBubble message={m} />
          </Box>
        ))}
        <Box aria-live="polite">
          {messageToRead && <MessageBubble message={messageToRead} />}
          {awaitingResponse && (
            <FlexBox justifyContent="left">
              <Box
                bg="navy-100"
                p={16}
                pb={24}
                borderRadius="lg"
                borderRadiusBottomLeft="none"
                mr={32}
              >
                <AnimatedLoadingDots />
              </Box>
            </FlexBox>
          )}
        </Box>
        {alert && (
          <Box
            aria-labelledby="chatbox-alert-msg"
            pt={8}
            mt="auto"
            width="100%"
          >
            <Alert
              placement="inline"
              type={alert.type}
              onClose={() => {
                setAlert(null);
                setDisclaimerSeen(true);
                inputRef.current?.focus();
              }}
            >
              <Box id="chatbox-alert-msg">{alert.msg}</Box>
            </Alert>
          </Box>
        )}
      </ScrollBox>
      <ChatInput
        inputRef={inputRef}
        postMessage={(content) => {
          setMessages([...messages, createUserMessage(content)]);
          setAlert(null);
          setDisclaimerSeen(true);
        }}
        awaitingResponse={awaitingResponse}
      />
      <FlexBox
        justifyContent="space-between"
        px={16}
        py={8}
        alignItems="center"
      >
        <NextLink
          href="https://codecademyready.typeform.com/to/PRJbh7Kj"
          passHref
        >
          <Anchor variant="interface" target="_blank">
            <Text variant="p-small" textDecoration="underline">
              Help us improve
            </Text>
          </Anchor>
        </NextLink>
        <FlexBox
          alignItems="center"
          onKeyDown={(evt) => {
            if (evt.key === 'Escape') {
              const infoTipIsOpen =
                (evt.target as HTMLElement).getAttribute('aria-expanded') ===
                'true';
              if (infoTipIsOpen) {
                evt.stopPropagation();
              }
            }
          }}
        >
          <StyledText variant="p-small">Powered by OpenAI</StyledText>{' '}
          <InfoTip
            alignment="top-left"
            info="The large language model may occasionally generate incorrect information."
          />
        </FlexBox>
      </FlexBox>
      {showDeleteDialog && (
        <Dialog
          isOpen
          onRequestClose={() => {
            setShowDeleteDialog(false);
          }}
          title="Clear chat?"
          confirmCta={{ children: 'Clear chat', onClick: deleteChat }}
          cancelCta={{ children: 'Cancel', onClick: () => null }}
        >
          <Text
            ref={(el) => {
              /*
               * Since the chatbox is rendered in a special portal, we need to
               * adjust the dialog portal's zIndex accordingly.
               */
              const dialogPortal = el?.closest('body > div');
              if (dialogPortal) {
                (dialogPortal as HTMLDivElement).style.zIndex = '12';
              }
            }}
          >
            This will permanently remove your conversation history in this
            thread.
          </Text>
        </Dialog>
      )}
      <Box position="absolute">{captcha}</Box>
    </FlexBox>
  );
};
