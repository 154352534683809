import { Box, IconButton, TextArea } from '@codecademy/gamut';
import { SendIcon } from '@codecademy/gamut-icons';
import { css, pxRem, states } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import React, { useState } from 'react';

export const StyledTextArea = styled(TextArea)(
  css({
    padding: pxRem(16),
    lineHeight: 'normal',
    bg: 'background',
    resize: 'none',
    maxHeight: pxRem(142),
    overflow: 'hidden',
    overflowY: 'auto',
    paddingRight: pxRem(66),
    display: 'block',
    '&::-webkit-scrollbar-track': {
      borderRadius: 'lg',
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar': {
      width: pxRem(5),
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#8d8c94',
      borderRadius: 'lg',
    },
  })
);

export const SendBtn = styled(IconButton)<{ isEnabled?: boolean }>(
  states({
    isEnabled: {
      bg: 'primary-hover',
      color: 'black',
      '&:hover': {
        color: 'black',
      },
    },
  })
);

interface ChatInputProps {
  postMessage: (message: string) => void;
  awaitingResponse: boolean;
  inputRef: React.RefObject<HTMLTextAreaElement>;
}

export const ChatInput: React.FC<ChatInputProps> = ({
  postMessage,
  awaitingResponse,
  inputRef,
}) => {
  const [message, setMessage] = useState('');
  const disabled = message.trim() === '' || awaitingResponse;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (!disabled) {
        postMessage(message);
        setMessage('');
      }
    }
  };

  return (
    <Box position="relative" py={8} px={{ _: 12, xs: 16 }}>
      <StyledTextArea
        ref={inputRef}
        placeholder="Ask your question"
        data-testid="ai-prompt-textarea"
        onKeyDown={handleKeyDown}
        onChange={(e) => setMessage(e.target.value)}
        value={message}
        role="textbox"
        aria-label="AI Prompt"
        rows={1}
        id="ai-chat-input"
      />
      <Box position="absolute" right={24} bottom={12}>
        <SendBtn
          icon={SendIcon}
          data-testid="generate-button"
          type="submit"
          tip="Submit message"
          onClick={() => {
            postMessage(message);
            setMessage('');
          }}
          disabled={disabled}
          height={40}
          width={36}
        />
      </Box>
    </Box>
  );
};
