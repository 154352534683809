import { HTMLToReactNode } from '@codecademy/gamut/dist/Markdown/libs/overrides';

/**
 * Check if the node is a ul and if it is preceeded by text that introduces a prompt list.
 */
export function isPromptList(node: HTMLToReactNode): boolean {
  if (node.name !== 'ul') {
    return false; // It's not a ul.
  }

  // Get the paragraph before. Use double prev to skip empty line between.
  const p = node.prev?.prev;

  if (p?.name !== 'p') {
    return false; // p isn't a paragraph.
  }

  const pText = p.children?.[0]?.data;
  if (!pText) {
    return false; // couldn't get text from p
  }

  // return true if p ends with text that introduces a prompt list.
  return !!pText.toLowerCase().match(/are you (looking to|interested in):$/);
}

export type MarkdownUlProps = {
  key: number;
  children: React.ReactElement[];
};

/**
 * Get string[] of prompts from li nodes.
 */
export function getInnerPrompts(props: MarkdownUlProps): string[] {
  return props.children
    .filter((c) => c?.type === 'li')
    .map((c) => c.props.children);
}
