import { Box, Card, CardProps, FlexBox, Shimmer } from '@codecademy/gamut';
import React from 'react';

import { Divider } from '../ContentGroupBaseCard/shared';

type LoadingCurriculumCardProps = {
  variant?: CardProps['variant'];
};

export const LoadingCurriculumCard: React.FC<LoadingCurriculumCardProps> = ({
  variant,
}) => (
  <Card
    variant={variant || 'beige'}
    borderRadius="md"
    borderColor="border-primary"
    overflow="hidden"
    display="flex"
    flexDirection="column"
    p={0}
    height="100%"
    width="100%"
  >
    <FlexBox
      flexDirection="column"
      textAlign="left"
      px={12}
      pt={4}
      pb={0}
      height="100%"
    >
      <Box pt={8}>
        <Shimmer width="40%" height={12} />
      </Box>
      <FlexBox
        mt="auto"
        pt={16}
        height="100%"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box pb={32}>
          <Shimmer width="70%" height={20} pt={8} />
          <Shimmer width="62%" height={20} pt={8} />
          <Shimmer width="61%" height={20} pt={8} />
        </Box>
        <Box>
          <Divider />
          <FlexBox justifyContent="space-between" flexWrap="wrap">
            <FlexBox>
              <Shimmer width={25} height={20} pb={8} />
              <Shimmer width={45} height={20} pb={8} pl={4} />
            </FlexBox>
            <FlexBox>
              <Shimmer width={25} height={20} pb={8} />
              <Shimmer width={45} height={20} pb={8} pl={4} />
            </FlexBox>
          </FlexBox>
        </Box>
      </FlexBox>
    </FlexBox>
  </Card>
);
