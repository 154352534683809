import { Box, FlexBox, Modal, Text } from '@codecademy/gamut';
import React, { useEffect, useMemo, useState } from 'react';

import formImage from '../assets/studyPlanBanner.svg';
import {
  calculateTargetTimeFrame,
  calculateTimeLeftToComplete,
  formatContainerTimeToCompleteText,
  formatContainerType,
  formatFormErrorMessage,
  validateStudyPlanFormInput,
} from '../helpers';
import {
  StudyPlanInputErrorMessageValues,
  StudyPlanInputValues,
} from '../types';
import { StudyPlanForm } from './StudyPlanForm';
import { StudyPlanModalFooter } from './StudyPlanModalFooter';

interface StudyPlanModalProps {
  containerTimeToComplete?: number | null;
  containerPercentComplete?: number | null;
  containerTitle?: string | null;
  containerType?: string;
  handleDeleteStudyPlanClick: () => void;
  isEditModal?: boolean;
  isOpen: boolean;
  isSaveLoading: boolean;
  onCreateStudyPlanSave: () => void;
  onRequestClose: () => void;
  setStudyPlanInputValues: React.Dispatch<
    React.SetStateAction<StudyPlanInputValues>
  >;
  studyPlanInputValues: StudyPlanInputValues;
}

export const StudyPlanModal: React.FC<StudyPlanModalProps> = ({
  containerTimeToComplete,
  containerPercentComplete,
  containerTitle,
  containerType = 'Course',
  handleDeleteStudyPlanClick,
  isEditModal,
  isOpen,
  isSaveLoading,
  onCreateStudyPlanSave,
  onRequestClose,
  setStudyPlanInputValues,
  studyPlanInputValues,
}) => {
  const [studyPlanInputError, setStudyPlanInputError] =
    useState<StudyPlanInputErrorMessageValues>({
      sessionLength: undefined,
      sessionsPerWeek: undefined,
    });

  const hasStudyPlanFormInputErrors =
    !!studyPlanInputError.sessionLength ||
    !!studyPlanInputError.sessionsPerWeek;

  const handleStudyPlanFormInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    const { isValidFormInputValue } = validateStudyPlanFormInput(name, value);
    const errorMessage = formatFormErrorMessage(name, value);

    if (!isValidFormInputValue) {
      return setStudyPlanInputError(
        (prev: StudyPlanInputErrorMessageValues) => ({
          ...prev,
          [name]: errorMessage,
        })
      );
    }

    setStudyPlanInputError((prev: StudyPlanInputErrorMessageValues) => ({
      ...prev,
      [name]: undefined,
    }));
    setStudyPlanInputValues((prev: StudyPlanInputValues) => ({
      ...prev,
      [name]: Number(value),
    }));
  };

  const timeLeftToCompleteContainer = calculateTimeLeftToComplete(
    containerTimeToComplete,
    containerPercentComplete
  );

  const containerTimeToCompleteText = formatContainerTimeToCompleteText(
    timeLeftToCompleteContainer,
    containerTitle
  );

  const targetTimeFrameInWeeks = useMemo(() => {
    return calculateTargetTimeFrame(
      studyPlanInputValues.sessionsPerWeek,
      studyPlanInputValues.sessionLength,
      containerPercentComplete,
      containerTimeToComplete
    );
  }, [
    containerPercentComplete,
    containerTimeToComplete,
    studyPlanInputValues.sessionLength,
    studyPlanInputValues.sessionsPerWeek,
  ]);

  useEffect(() => {
    setStudyPlanInputValues((prev: StudyPlanInputValues) => ({
      ...prev,
      targetTimeFrame: targetTimeFrameInWeeks * 7, // save to db as days,
    }));
  }, [targetTimeFrameInWeeks, setStudyPlanInputValues]);

  const modalTitleText = `${formatContainerType(containerType)} study plan`;

  const modalTitle = (
    <Box pb={12}>
      <Text>{modalTitleText}</Text>
    </Box>
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={modalTitle}
      size="small"
    >
      <FlexBox height="100%" data-testid="study-plan-modal" column p={0} mb={4}>
        <FlexBox maxHeight={94} center width="100%" mb={12}>
          <Box
            width="100%"
            height={94}
            minWidth="calc(100% + 48px)"
            position="relative"
            backgroundImage={`url(${formImage})`}
          />
        </FlexBox>
        <FlexBox overflowY="auto" overflowX="hidden" height="100%" column>
          <Text mb={24} mt={16}>
            {containerTimeToCompleteText}
          </Text>
          <StudyPlanForm
            handleInputChange={handleStudyPlanFormInputChange}
            studyPlanInputErrorMessage={studyPlanInputError}
            studyPlanInputValues={studyPlanInputValues}
            targetTimeFrameInWeeks={targetTimeFrameInWeeks}
          />
          <StudyPlanModalFooter
            hasStudyPlanFormInputErrors={hasStudyPlanFormInputErrors}
            handleDeleteStudyPlanClick={handleDeleteStudyPlanClick}
            isEditModal={isEditModal}
            isSaveLoading={isSaveLoading}
            onCreateStudyPlanSave={onCreateStudyPlanSave}
            onRequestClose={onRequestClose}
            studyPlanInputValues={studyPlanInputValues}
            targetTimeFrameInWeeks={targetTimeFrameInWeeks}
          />
        </FlexBox>
      </FlexBox>
    </Modal>
  );
};
