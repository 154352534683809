import { ApolloError } from '@apollo/client';
import { FlexBox } from '@codecademy/gamut';
import { SessionContentItem } from '@mono/data/codegen/client/graphql-gateway/graphql';
import React, { useState } from 'react';

import {
  getContentItemsToShow,
  getSessionContainerSlugs,
  maxContentItemsToShow,
} from '../helpers';
import { StudyPlanContentItemType } from '../types';
import { ExpandContentItemListButton } from './ExpandContentItemListButton';
import { SuggestedContentItemButton } from './SuggestedContentItemButton';

interface SuggestedContentItemsProps {
  containerType: string;
  contentItems?: StudyPlanContentItemType[] | null;
  isEnrolledSyllabus?: boolean;
  isStudyPlanUpsell?: boolean;
  onExpandContentItemListClick: (isExpanded: boolean) => void;
  onSuggestedContentItemClick: (contentItemId: string) => void;
  sessionContentItems?: SessionContentItem[] | null;
  suggestedContentItemError?: boolean | ApolloError;
}

export const SuggestedContentItems: React.FC<SuggestedContentItemsProps> = ({
  containerType,
  contentItems,
  isEnrolledSyllabus,
  isStudyPlanUpsell,
  onExpandContentItemListClick,
  onSuggestedContentItemClick,
  sessionContentItems,
  suggestedContentItemError,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!contentItems || suggestedContentItemError) return null;

  const contentItemsToShow = getContentItemsToShow(
    contentItems as StudyPlanContentItemType[],
    isExpanded
  );

  const showExpandContentItemListButton =
    contentItems.length > maxContentItemsToShow && !isStudyPlanUpsell;
  const remainingContentItemsAmount =
    contentItems.length - maxContentItemsToShow;

  return (
    <FlexBox column>
      <FlexBox
        column
        p={0}
        mb={isEnrolledSyllabus ? 0 : 16}
        as="ul"
        listStyleType="none"
      >
        {contentItemsToShow.map((contentItem: StudyPlanContentItemType) => {
          const { moduleSlug, trackSlug, pathSlug } = getSessionContainerSlugs(
            contentItem.id,
            sessionContentItems
          );
          return (
            <FlexBox as="li" key={contentItem.id} column>
              <SuggestedContentItemButton
                containerType={containerType}
                contentItem={contentItem}
                isEnrolledSyllabus={isEnrolledSyllabus}
                isStudyPlanUpsell={isStudyPlanUpsell}
                moduleSlug={moduleSlug}
                onSuggestedContentItemClick={onSuggestedContentItemClick}
                trackSlug={trackSlug}
                pathSlug={pathSlug}
              />
            </FlexBox>
          );
        })}
      </FlexBox>
      {showExpandContentItemListButton && (
        <ExpandContentItemListButton
          handleExpandContentItemListClick={() =>
            onExpandContentItemListClick(isExpanded)
          }
          isExpanded={isExpanded}
          remainingContentItemsAmount={remainingContentItemsAmount}
          setIsExpanded={setIsExpanded}
        />
      )}
    </FlexBox>
  );
};
