import { Box, FlexBox, Form, Input, Text } from '@codecademy/gamut';
import React from 'react';

import {
  formatTargetTimeFrameText,
  studyPlanDefaultInputValues,
  studyPlanIncrementValues,
} from '../helpers';
import {
  StudyPlanInputErrorMessageValues,
  StudyPlanInputValues,
} from '../types';

interface StudyPlanFormProps {
  handleInputChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  studyPlanInputErrorMessage: StudyPlanInputErrorMessageValues;
  studyPlanInputValues: StudyPlanInputValues;
  targetTimeFrameInWeeks: number;
}

export const StudyPlanForm: React.FC<StudyPlanFormProps> = ({
  handleInputChange,
  studyPlanInputErrorMessage,
  studyPlanInputValues,
  targetTimeFrameInWeeks,
}) => {
  const { sessionLength, sessionsPerWeek } = studyPlanInputValues;

  const {
    sessionLength: sessionLengthInputErrorMessage,
    sessionsPerWeek: sessionsPerWeekInputErrorMessage,
  } = studyPlanInputErrorMessage;

  const {
    sessionLength: sessionLengthDefault,
    sessionsPerWeek: sessionsPerWeekDefault,
  } = studyPlanDefaultInputValues;

  const {
    sessionLength: sessionLengthIncrement,
    sessionsPerWeek: sessionsPerWeekIncrement,
  } = studyPlanIncrementValues;

  const targetTimeFrameText = formatTargetTimeFrameText(targetTimeFrameInWeeks);

  return (
    <FlexBox column borderRadius="md" bg="background-selected">
      <FlexBox column px={24} pt={24} pb={16}>
        <Text fontWeight={700} mb={12}>
          What’s a good time commitment?
        </Text>
        <Form>
          <FlexBox column>
            <FlexBox
              justifyContent="space-between"
              center
              mb={sessionsPerWeekInputErrorMessage ? 8 : 16}
            >
              <FlexBox>
                <Text>Days per week</Text>
                <Text as="span" aria-hidden>
                  *
                </Text>
              </FlexBox>
              <Box width="100%" maxWidth={100}>
                <Input
                  htmlFor="sessionsPerWeek"
                  required
                  aria-label="Days per week"
                  name="sessionsPerWeek"
                  type="number"
                  max={7}
                  min={sessionsPerWeekIncrement}
                  step={sessionsPerWeekIncrement}
                  error={!!sessionsPerWeekInputErrorMessage}
                  defaultValue={sessionsPerWeek || sessionsPerWeekDefault}
                  onChange={handleInputChange}
                />
              </Box>
            </FlexBox>
            {sessionsPerWeekInputErrorMessage && (
              <FlexBox alignSelf="flex-end" mb={16}>
                <Text fontSize={14} textColor="red-600">
                  {sessionsPerWeekInputErrorMessage}
                </Text>
              </FlexBox>
            )}
            <FlexBox justifyContent="space-between" center>
              <FlexBox>
                <Text>Hours per day</Text>
                <Text as="span" aria-hidden>
                  *
                </Text>
              </FlexBox>
              <Box width="100%" maxWidth={100}>
                <Input
                  htmlFor="sessionLength"
                  name="sessionLength"
                  required
                  aria-label="Hours per day"
                  type="number"
                  max={8}
                  min={sessionLengthIncrement}
                  step={sessionLengthIncrement}
                  error={!!sessionLengthInputErrorMessage}
                  defaultValue={sessionLength || sessionLengthDefault}
                  onChange={handleInputChange}
                />
              </Box>
            </FlexBox>
            {sessionLengthInputErrorMessage && (
              <FlexBox alignSelf="flex-end" fontSize={14} mt={8}>
                <Text textColor="red-600">
                  {sessionLengthInputErrorMessage}
                </Text>
              </FlexBox>
            )}
          </FlexBox>
        </Form>
      </FlexBox>
      <FlexBox
        borderTop={1}
        borderColor="background-selected"
        px={24}
        py={16}
        justifyContent="space-between"
        bg="background-selected"
        center
      >
        <Text>Estimated time to finish</Text>
        <Text fontSize={20} fontWeight={700}>
          {targetTimeFrameText}
        </Text>
      </FlexBox>
    </FlexBox>
  );
};
