import { Badge, FlexBox, Text, TextButton } from '@codecademy/gamut';
import React from 'react';

import { getShouldDisplayNewBadge } from './helpers';

interface CallToActionConfig {
  text: string;
  onClick: () => void;
}
interface CreateStudyPlanSectionContentProps {
  description: string;
  isColumnView?: boolean;
  primaryCta: CallToActionConfig;
  secondaryCta?: CallToActionConfig;
  title: string;
}

export const CreateStudyPlanSectionContent: React.FC<
  CreateStudyPlanSectionContentProps
> = ({ description, isColumnView, primaryCta, secondaryCta, title }) => {
  const { onClick, text } = primaryCta;
  const shouldShowDismissButton = secondaryCta?.text && secondaryCta?.onClick;
  const shouldDisplayNewBadge = getShouldDisplayNewBadge();

  const headerContent = (
    <FlexBox column>
      <FlexBox>
        <Text fontWeight={700}>{title}</Text>
        {shouldDisplayNewBadge && (
          <Badge size="sm" variant="accent" ml={8} mt={4}>
            New
          </Badge>
        )}
      </FlexBox>
      <Text mt={isColumnView ? 8 : 4}>{description}</Text>
    </FlexBox>
  );

  const primaryButton = (
    <TextButton border={2} p={8} mb={isColumnView ? 8 : 0} onClick={onClick}>
      <Text textColor="black">{text}</Text>
    </TextButton>
  );

  return (
    <>
      {isColumnView ? (
        <FlexBox column>
          {headerContent}
          <FlexBox mt={12} gap={8}>
            {primaryButton}
            {shouldShowDismissButton && (
              <TextButton onClick={secondaryCta.onClick} py={8} px={4}>
                <Text textColor="black">{secondaryCta.text}</Text>
              </TextButton>
            )}
          </FlexBox>
        </FlexBox>
      ) : (
        <FlexBox
          alignItems={{ _: 'flex-start', md: 'center' }}
          flexDirection={{ _: 'column', md: 'row' }}
          justifyContent={{ _: 'flex-start', md: 'space-between' }}
          width="100%"
        >
          {headerContent}
          <FlexBox ml={{ _: 0, md: 24 }} mt={{ _: 16, md: 0 }}>
            {primaryButton}
          </FlexBox>
        </FlexBox>
      )}
    </>
  );
};
