import { HeadingTags } from '@codecademy/gamut';

import { ContentGroupBaseCard } from '../ContentGroupBaseCard';
import { CourseCountComponent, Divider } from '../ContentGroupBaseCard/shared';
import {
  CourseDifficulty,
  EnrollmentStatus,
} from '../ContentGroupBaseCard/types';

export interface CertificationPathCardProps {
  title: string;
  courseCount: number;
  enrollmentStatus: EnrollmentStatus;
  shortDescription?: string | null;
  difficulty?: CourseDifficulty | null;
  provider?: string;
  providerLogoUrl?: string;
  headingLevel?: HeadingTags;
  timeToComplete?: number | null;
}

export const CertificationPathCard: React.FC<CertificationPathCardProps> = ({
  title,
  courseCount,
  enrollmentStatus,
  shortDescription,
  difficulty,
  provider,
  providerLogoUrl,
  headingLevel,
  timeToComplete,
}) => {
  return (
    <ContentGroupBaseCard
      // TODO: replace once color orange-100 is added to gamut
      headerBackgroundColor="orange"
      headerText="Certification path"
      title={title}
      headingLevel={headingLevel}
      description={shortDescription}
      difficulty={difficulty}
      numLessons={courseCount}
      shadow="outline"
      enrollmentStatus={enrollmentStatus}
      timeToComplete={timeToComplete}
      brandDetails={{ name: provider || '', logoUrl: providerLogoUrl || '' }}
      overrideColor
    >
      <Divider />
      <CourseCountComponent count={courseCount} />
    </ContentGroupBaseCard>
  );
};
