import { Box, FlexBox } from '@codecademy/gamut';

import { ChatboxPromptButton } from '../../AnonAIPrompts';
import { createUserMessage } from '../message';
import { setInjectedMessage } from '../state';

export const InlinePrompts = ({ prompts }: { prompts: string[] }) => (
  <FlexBox as="ul" mt={0} mb={16} p={0} gap={8} flexDirection="column">
    {prompts.map((prompt) => (
      <Box
        key={prompt}
        as="li"
        listStyleType="none"
        display="block"
        bg="paleBlue"
        borderRadius="md"
      >
        <ChatboxPromptButton
          variant="withinMessage"
          onClick={() => setInjectedMessage(createUserMessage(prompt))}
        >
          {prompt}
        </ChatboxPromptButton>
      </Box>
    ))}
  </FlexBox>
);
