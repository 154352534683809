import { EnrollmentStatus } from '@mono/brand';
import {
  CourseDifficulty,
  ExternalPathClassification,
  RawExtCourseCardDataFragment,
  RawExtPathCardDataFragment,
  RawPathCardDataFragment,
  RawTrackCardDataFragment,
} from '@mono/data/codegen/client/graphql-gateway/graphql';
import { getLearnPath, getPathImageUrl } from '@mono/util/url';

import { careerPathHasJourney } from './careerJourneyHelpers';
import {
  CourseData,
  CurriculumCardData,
  ExternalCertPathData,
  PathData,
  PathGoalEnum,
} from './types';

export const getTimeToComplete = (hours: number, isCareerPath?: boolean) => {
  // round career path hour estimates to the nearest 5 or 0
  return isCareerPath ? Math.round(hours / 5) * 5 : Math.round(hours);
};

export const trackToCourseData = (
  raw: RawTrackCardDataFragment
): CourseData => {
  const rawTTC = raw.metrics.medianDurationHours;
  return {
    id: raw.id,
    slug: raw.slug,
    urlPath: getLearnPath({ slug: raw.slug }),
    title: raw.title ?? '',
    lessonCount: raw.lessonCount,
    contentModuleIds: raw.contentModuleIds,
    grantsCertificate: raw.grantsCertificate,
    enrollmentStatus: raw.enrollmentStatus || EnrollmentStatus.None,
    shortDescription: raw.shortDescription ?? null,
    difficulty: raw.difficulty ? toDifficultyEnum(raw.difficulty) : null,
    pro: raw.pro,
    timeToComplete: rawTTC ? getTimeToComplete(rawTTC) : null,
    type: 'course',
  };
};

export const toPathData = (raw: RawPathCardDataFragment): PathData => {
  const goal = toPathGoalEnum(raw.goal);
  const isCareerPath = goal === PathGoalEnum.Career;
  const rawTTC = raw.metrics.medianDurationHours;

  return {
    id: raw.id,
    slug: raw.slug,
    urlPath: getLearnPath({ slug: raw.slug, isPath: true }),
    imageUrl: getPathImageUrl(raw.slug),
    goal,
    title: raw.title ?? '',
    lessonCount: raw.lessonCount,
    courseCount: raw.trackCount,
    enrollmentStatus: raw.enrollmentStatus || EnrollmentStatus.None,
    shortDescription: raw.shortDescription ?? null,
    difficulty: raw.difficulty ? toDifficultyEnum(raw.difficulty) : null,
    timeToComplete: rawTTC ? getTimeToComplete(rawTTC, isCareerPath) : null,
    hasCareerJourney: careerPathHasJourney(raw.slug),
    type: 'path',
  };
};

export const extCourseToCourseData = (
  raw: RawExtCourseCardDataFragment
): CourseData => {
  return {
    id: raw.id,
    slug: raw.slug,
    urlPath: getLearnPath({ slug: raw.slug, isExtCourse: true }),
    title: raw.title ?? '',
    lessonCount: 0,
    contentModuleIds: [],
    grantsCertificate: raw.grantsCertificate,
    enrollmentStatus: raw.enrollmentStatus || EnrollmentStatus.None,
    shortDescription: raw.shortDescription || raw.longDescription || null,
    difficulty: raw.difficulty ? toDifficultyEnum(raw.difficulty) : null,
    pro: raw.pro,
    timeToComplete: getTimeToComplete(raw.durationHours),
    type: 'course',
  };
};

export const extPathToSkillPathData = (
  raw: RawExtPathCardDataFragment
): PathData => {
  return {
    id: raw.id,
    slug: raw.slug,
    urlPath: getLearnPath({ slug: raw.slug, isExtPath: true }),
    imageUrl: getPathImageUrl(raw.slug), // will render placeholder
    goal: PathGoalEnum.Skill,
    title: raw.title ?? '',
    lessonCount: 0,
    courseCount: raw.courseCount,
    enrollmentStatus: raw.enrollmentStatus || EnrollmentStatus.None,
    shortDescription: raw.shortDescription ?? null,
    difficulty: raw.difficulty ? toDifficultyEnum(raw.difficulty) : null,
    timeToComplete: getTimeToComplete(raw.durationHours),
    hasCareerJourney: careerPathHasJourney(raw.slug),
    type: 'path',
  };
};

export const toExternalCertPathData = (
  raw: RawExternalCertPath
): ExternalCertPathData => {
  return {
    id: raw.id,
    slug: raw.slug,
    urlPath: getLearnPath({ slug: raw.slug, isExtPath: true }),
    title: raw.title ?? '',
    courseCount: raw.courseCount,
    enrollmentStatus: raw.enrollmentStatus || EnrollmentStatus.None,
    difficulty: raw.difficulty ? toDifficultyEnum(raw.difficulty) : null,
    timeToComplete: getTimeToComplete(raw.durationHours),
    shortDescription: raw.shortDescription ?? null,
    type: 'externalCertPath',
  };
};

export const toDifficultyEnum = (raw: string): CourseDifficulty =>
  raw as CourseDifficulty;

// defaulting to skillpath as most common
export const toPathGoalEnum = (raw?: string | undefined | null): PathGoalEnum =>
  raw === 'career' ? PathGoalEnum.Career : PathGoalEnum.Skill;

type RawContent =
  | RawTrackCardDataFragment
  | RawPathCardDataFragment
  | RawExtCourseCardDataFragment
  | RawExtPathCardDataFragment;

type RawExternalCertPath = RawExtPathCardDataFragment & {
  classification: ExternalPathClassification.CertificationPath;
};

const isRawTrack = (raw: RawContent): raw is RawTrackCardDataFragment => {
  return raw.__typename === 'Track';
};

const isRawPath = (raw: RawContent): raw is RawTrackCardDataFragment => {
  return raw.__typename === 'Path';
};

const isRawExtCourse = (
  raw: RawContent
): raw is RawExtCourseCardDataFragment => {
  return raw.__typename === 'ExternalCourse';
};

export const isRawExternalCertPath = (
  raw: RawContent
): raw is RawExternalCertPath => {
  return (
    raw.__typename === 'ExternalPath' &&
    raw.classification === ExternalPathClassification.CertificationPath
  );
};

export const rawToFinalSingleCard = (
  r: RawContent,
  renderNewCertPath: boolean
): CurriculumCardData => {
  if (isRawTrack(r)) {
    return trackToCourseData(r);
  }
  if (isRawPath(r)) {
    return toPathData(r);
  }
  if (isRawExtCourse(r)) {
    return extCourseToCourseData(r);
  }
  if (isRawExternalCertPath(r)) {
    return renderNewCertPath
      ? toExternalCertPathData(r)
      : extPathToSkillPathData(r);
  }
  throw new Error(
    `Unrecognized raw curriculum card data type. Data: ${JSON.stringify(r)}`
  );
};

export const rawToFinalCardData = (
  raw: RawContent[],
  renderNewCertPaths: boolean
): CurriculumCardData[] => {
  return raw.map((r) => rawToFinalSingleCard(r, renderNewCertPaths));
};
