import { CourseDifficulty, EnrollmentStatus } from '@mono/brand';
import {
  EnrolledPathQuery,
  EnrolledTrackQuery,
  JourneyResumeLocationFragment,
  JourneyResumeQuery,
  PathResumeLocationFragment,
  PathResumeQuery,
  TrackResumeLocationFragment,
  TrackResumeQuery,
} from '@mono/data/codegen/client/graphql-gateway/graphql';

/*
 These types should be kept as slim as possible
and only contain the fields required to render a VariableCurriculumCard.

 If you are fetching additional metadata for a course or path,
feel free to extend these base types in your own file somewhere.

 Example:
 ```ts
 <in some other file somewhere close to your fetching code>

 type MyExtraData = { additionalField: 'foo' };
 type CourseDataWithMyExtraData = CourseData & MyExtraData;
 ```

 For a full example, see:
 https://github.com/codecademy-engineering/mono/blob/e00fdabd3ac0d77d26901fdb43e864128d9ecf49/apps/portal-app/src/components/CatalogPage/HubPage/types.ts#L38
 +
 https://github.com/codecademy-engineering/mono/blob/e00fdabd3ac0d77d26901fdb43e864128d9ecf49/apps/portal-app/src/components/CatalogPage/HubPage/queries.graphql-gateway.ts#L4
 +
 https://github.com/codecademy-engineering/mono/blob/e00fdabd3ac0d77d26901fdb43e864128d9ecf49/apps/portal-app/src/components/CatalogPage/HubPage/helpers.ts#L123
*/

export type CourseData = {
  id: string;
  slug: string;
  urlPath: string;
  title: string;
  lessonCount: number;
  contentModuleIds: string[];
  grantsCertificate: boolean;
  enrollmentStatus: EnrollmentStatus;
  pro: boolean;
  shortDescription?: string | null;
  difficulty?: CourseDifficulty | null;
  timeToComplete: number | null;
  type: 'course';
};

export enum PathGoalEnum {
  Career = 'career',
  Skill = 'skill',
}

// See code comment at top of file
export type PathData = {
  id: string;
  slug: string;
  urlPath: string;
  imageUrl: string;
  goal: PathGoalEnum;
  title: string;
  lessonCount: number;
  courseCount: number;
  enrollmentStatus: EnrollmentStatus;
  shortDescription?: string | null;
  difficulty?: CourseDifficulty | null;
  timeToComplete: number | null;
  hasCareerJourney: boolean; // career paths only
  type: 'path';
};

// See code comment at top of file
export type ExternalCertPathData = {
  id: string;
  slug: string;
  urlPath: string;
  title: string;
  courseCount: number;
  enrollmentStatus: EnrollmentStatus;
  difficulty?: CourseDifficulty | null;
  timeToComplete: number | null;
  shortDescription?: string | null;
  type: 'externalCertPath';
};

export type CurriculumCardData = CourseData | PathData | ExternalCertPathData;

export const isCourseData = (
  content: CurriculumCardData
): content is CourseData => content.type === 'course';

export const isPathData = (content: CurriculumCardData): content is PathData =>
  content.type === 'path';

export const isExternalCertPathData = (
  content: CurriculumCardData
): content is ExternalCertPathData => content.type === 'externalCertPath';

type JourneyResumeContent = NonNullable<
  JourneyResumeLocationFragment['resumeContent']
>;
type PathResumeContent = NonNullable<
  PathResumeLocationFragment['resumeContent']
>;
type TrackResumeContent = NonNullable<
  TrackResumeLocationFragment['resumeContent']
>;

export type ResumeContent =
  | JourneyResumeContent
  | PathResumeContent
  | TrackResumeContent;

export type DashboardResumeContainer =
  | NonNullable<JourneyResumeQuery['journey']>
  | NonNullable<PathResumeQuery['path']>
  | NonNullable<TrackResumeQuery['track']>;

export type SyllabusResumeContainer =
  | NonNullable<EnrolledPathQuery['path']>
  | NonNullable<EnrolledTrackQuery['track']>;

export type ResumeContainer =
  | DashboardResumeContainer
  | SyllabusResumeContainer;

export const isJourneyResumeContent = (
  resumeContent: ResumeContent
): resumeContent is JourneyResumeContent =>
  'type' in resumeContent && resumeContent.type === 'JourneyResumeLocationV2';

export const isPathResumeContent = (
  resumeContent: ResumeContent
): resumeContent is PathResumeContent =>
  'type' in resumeContent && resumeContent.type === 'PathResumeLocationV2';

export const isTrackResumeContent = (
  resumeContent: ResumeContent
): resumeContent is TrackResumeContent =>
  'type' in resumeContent && resumeContent.type === 'TrackResumeLocationV2';
